.socials {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 82px;
  background-color: hsla(0, 0%, 7%, 0.25);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 0 2.25rem;
  z-index: 20;
}

.socials a {
  opacity: 0.5;
  height: 24px;
}

.socials a:hover {
  color: var(--accent-clr);
  opacity: 1 !important;
}

.icon-container {
  display: flex;
  gap: 1rem;
  flex-shrink: 2;
}

.resume-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid var(--accent-clr);
  color: var(--accent-clr);
  font-size: var(--text-400);
  cursor: pointer;
  overflow: hidden;
  z-index: 1;
  height: fit-content !important;
  border-radius: 4px;
  transition: color 0.4s ease-in-out;
}

.resume-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--accent-clr);
  z-index: -1;
  transform: scale(0, 0);
  transform-origin: top left;
  transition: transform 0.4s ease-in-out;
}

.resume-btn:hover::before {
  transform: scale(1, 1);
  transform-origin: top left;
}

.resume-btn:hover {
  color: var(--bg) !important;
}

@media (max-width: 769px) {
  .socials {
    padding: 0 1rem;
  }
}
