.about {
  position: relative;
  max-width: 1150px;
  padding: 6rem;
  margin: 0 auto;
  z-index: 5;
  width: 100%;
  height: fit-content;
  background-color: var(--bg);
}

.header-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.header-line {
  width: 100%;
  height: 1px;
  background-color: var(--text-primary);
  opacity: 0.3;
}

.header-container h3 {
  font-size: var(--text-700);
  font-weight: 900;
  text-align: end;
  width: fit-content;
}

.about-content {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 1.5rem;
}

.about-description p {
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: var(--text-400);
}

.about-description .highlight-first::first-letter {
  background-color: var(--bg-light);
  padding: 0.625rem;
  border-radius: 0.25rem;
  font-size: var(--text-500);
  font-weight: 700;
  float: left;
  margin-right: 0.375rem;
}

.links-container h5 {
  color: var(--accent-clr);
  display: flex;
  align-items: center;
  font-size: var(--text-400);
  font-weight: 500;
  gap: 0.5rem;
  height: fit-content;
  margin: 0;

}

.links-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.links {
  display: flex;
  gap: 1rem;
}

.links a {
  opacity: 0.5;
}

.links a:hover {
  color: var(--accent-clr);
  opacity: 1;
}

.tech-section h4 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  font-size: var(--text-500);
  margin-bottom: 1.5rem;
}

.tech-list {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.tech-list span {
  border-radius: 9999px;
  background-color: var(--bg-light);
  padding: 0.125rem 0.5rem;
}

@media (max-width: 900px) {
  .about-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .about {
    padding: 4.5rem 1.5rem;
  }
  .header-container h3{ font-size: var(--text-600);}
}
