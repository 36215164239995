.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-card {
  padding: 3rem;
  background-color: var(--bg-light);
  width: fit-content;
  border-radius: 5px;
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-card h1 {
  text-align: center;
}

.login-card button {
  padding: 0 1rem;
}

.signout-btn {
margin: 2rem;
}

.project-form-container {
    padding: 2rem;
    background-color: var(--bg-light);
    border-radius: 5px;
    max-width: 1200px;
    width: 100%;
    margin: 2rem;
}

.project-form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

